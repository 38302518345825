.container > span {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.container > span > img {
    margin-top: 5px;
}

.container > span:hover {
    user-select: none;
    cursor: pointer;
}

.list {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    background-color: #ffffff;
    border-radius: 5px;
    top: 99px;
    left: 0;
    max-height: 130px;
    overflow-y: auto;
    width: 100%;
    z-index: 2;
}

.itemList{
    margin: 0;
    padding: 0;
    list-style: none;
}

.itemList > li {
    font-family: var(--mainFont);
    font-size: 18px;
    font-weight: 400;
    padding: 5px 15px;
    user-select: none;
    color: #393939;
}

.itemList > li:hover {
    cursor: pointer;
    background-color: #C4C2C2;
}

.itemList > li:not(:last-child) {
    border-bottom: 1px solid #C4C2C2;
}

.list.active {
    opacity: 1;
    pointer-events: auto;
}

.yearBtn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border: 0;
    background-color: transparent;
    font-family: var(--mainFont);
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
    gap: 8px;
    color: #393939;
}

.yearBtn svg {
    width: 20px;
    height: 20px;
    fill: #393939;
}

.yearBtn:hover {
    cursor: pointer;
}