.videoWrapper, .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.videoWrapper video {
    object-fit: cover;
}

@media (min-aspect-ratio: 16/9) {
    .videoWrapperResp { height: 300%; top: -100%; }
}

@media (max-aspect-ratio: 16/9) {
    .videoWrapperResp { width: 300%; left: -100%; }
}

@media screen and (max-width: 600px) {
    .videoWrapper {
        display: none;
    }
}