
.mobileVideoBackground {
    display: none;
}

.mobileVideoBackground iframe, .defaultVideo, .defaultVideo video {
    width: 100%;
    height: 206px;
}

.defaultVideo video {
    object-fit: cover;
}

@media screen and (max-width: 600px) {
    .mobileVideoBackground {
        display: flex;
    }
}