.wrapper {
  display: flex;
  background: #000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -99;
}

.wrapper.default {
  background-size: cover;
}

.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0,0,0, .4);
}

.body {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}


@media screen and (max-width: 600px) {
  .wrapper {
    position: unset;
    overflow-y: auto;
    background: linear-gradient(36deg, rgba(243,244,246,1) 0%, rgba(43,43,43,0.9) 59%, rgba(43,43,43,1) 100%);
    min-height: 100vh;
  }

  .container {
    position: unset;
    background: transparent;
  }

  .body {
    background: url('../../images/citchen-background.png');
  }
}