@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap');

:root {
    --mainFont: 'Open Sans', sans-serif;
    --color-white: #FFFFFF;
    --color-muted: #D8D8D8;
    --color-black: #000000;
    --color-border: #FECC00;
    --color-orange: #393939;
}

body {
    margin: 0;
    padding: 0;
}

.b24-form {
    display: none;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

@media screen and (max-width: 600px) {
    .b24-form {
        position: absolute;
        top: 0;
        z-index: 99;
        padding: 0 20px;
    }
}