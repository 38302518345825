.cityBlock {
    display: flex;
    flex-direction: column;
    color: #ffffff;
    row-gap: 7px;
}

.cityText {
    display: inherit;
    flex-direction: inherit;
    row-gap: 7px;
}

.cityText > p {
    font-family: var(--mainFont);
    margin: 0;
}

.cityText > p:first-child {
    font-weight: 700;
    font-size: 20px;
}

.cityText > p:last-child {
    font-weight: 500;
    font-size: 14px;
    color: #D8D8D8;
}

.citySelect {
    position: relative;
    background-color: #FFFFFFCC;
    font-weight: 700;
    font-size: 16px;
    color: #393939;
    padding: 7px 25px 7px 13px;
    height: 35px;
    border-radius: 7px;
    font-family: var(--mainFont);
    display: flex;
    align-items: center;
}

.citySelect > img {
    position: absolute;
    top: 45%;
    right: 13px;
    transition: .3s linear all;
}

.citySelect:hover {
    cursor: pointer;
}

.dropDown {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    background-color: #FFFFFFCC;
    border-radius: 7px;
    max-height: 250px;
    overflow-y: auto;
    font-family: var(--mainFont);
}

.citySelect.active > img {
    transform: rotate(180deg);
}

.citySelect.active ~ .dropDown {
    position: unset;
    opacity: 1;
    pointer-events: auto;
    transition: .3s linear all;
}

.dropDown > ul {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
}

.dropDown > ul > li {
    padding: 10px 13px;
    color: #393939;
    font-family: var(--mainFont);
    font-weight: 500;
    font-size: 16px;
    user-select: none;
    transition: .2s linear ease-in-out;
}

.dropDown > ul > li:first-child {
    border-radius: 7px 7px 0 0;
}

.dropDown > ul > li:last-child {
    border-radius: 0 0 7px 7px;
}

.dropDown > ul > li:hover {
    cursor: pointer;
    background-color: #C4C2C2;
}

@media screen and (max-width: 600px) {
    .cityText > p:first-child, .cityText > p:last-child {
        color: #000000;
    }

    .citySelect {
        padding: 12px 20px;
        background-color: #ffffff;
    }

    .dropDown {
        background-color: #ffffff;
    }

    .dropDown > ul > li:hover {
        background-color: #D9D9D9;
        font-weight: 600;
    }
}