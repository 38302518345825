.priceBlock {
    position: relative;
    opacity: 1;
    pointer-events: auto;
    background-color: #FFFFFFCC;
    border-radius: 5px;
    margin-top: 9px;
    transition: .3s linear ease-out;
}

.priceBlock > table {
    width: 100%;
    font-family: var(--mainFont);
    padding: 10px 0;
}

.priceBlock > table  tr:not(:last-child) > td {
    padding-bottom: 9px;
}

.priceBlock > table  tr > td:first-child {
    font-size: 18px;
    font-weight: 400;
    color: #393939;
    padding: 0 12px;
}

.priceBlock > table  tr > td:last-child {
    padding-right: 9px;
}

.priceBlock.hidden {
    opacity: 0;
    pointer-events: none;
    position: absolute;
}

@media screen and (max-width: 600px) {
    .priceBlock {
        background-color: #ffffff;
        padding: 0 20px;
    }

    .priceBlock > table tr > td:last-child span {
        background-color: #353535;
    }

    .priceBlock > table {
        border-collapse: collapse;
    }

    .priceBlock > table tr:not(:last-child) td {
        border-bottom: 1px solid #EAEAEA;
    }

    .priceBlock > table td {
        padding: 11px 0 !important;
    }

    .priceBlock > table tr > td:first-child {
        color: #000000;
    }
}