.container {
    display: block;
    background-color: #030303AD;
    color: #ffffff;
    font-weight: 500;
    font-size: 20px;
    border-radius: 5px;
    padding: 2px 10px;
    width: calc(100% - 20px);
    min-height: 26px;
}

.loading {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}