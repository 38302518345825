.aside {
    display: flex;
    flex-direction: column;
    min-width: 270px;
    min-height: 100vh;
    padding: 15px 13px 15px 20px;
    background-color: #414141CC;
}

.logoBlock {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.logoBlock div:first-of-type > img {
    width: 54px;
    height: 48px;
}

.logoBlock div:first-of-type > p {
    font-family: var(--mainFont);
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    color: var(--color-white);
    margin: 0;
}

.marketingText {
    margin-top: 14px;
}

.marketingText > p {
    font-family: var(--mainFont) !important;
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    margin: 0;
    color: var(--color-white);
    line-height: 25px;
    white-space: nowrap;
}

.marketingText > hr {
    border: 2px solid var(--color-border);
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.cityBlock {
    margin-top: 35px;
}

.content > p {
    font-family: var(--mainFont);
    font-weight: 500;
    font-size: 14px;
    color: var(--color-muted);
    margin: 12px 0 0;
    line-height: 120%;
}
.content > p > a {
    color: #FECC00;
    text-decoration: none;
    font-weight: 800;
}
.bitrixBlock {
    margin-top: 55px;
}

.bitrixBtn {
    display: flex;
    align-items: center;
    column-gap: 7px;
    background-color: #FECC00;
    border: none;
    font-family: var(--mainFont);
    font-weight: 700;
    font-size: 16px;
    color: var(--color-orange);
    padding: 7px 17px;
    border-radius: 7px;
    width: 100%;
    justify-content: center;
}

.bitrixBtn:hover {
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .aside {
        width: 100%;
        height: auto;
        background-color: #FECC00F2;
        padding: 0;
    }

    .logoBlock {
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 22px;
    }

    .logoBlock div:first-of-type > img {
        width: 44px;
        height: 39px;
    }

    .logoBlock, .content {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 17px;
    }

    .marketingText, .marketingText > p {
        margin: 2px 0 0;
        white-space: unset;
    }

    .marketingText {
        max-width: 213px;
    }

    .marketingText > p {
        font-size: 17px;
        color: var(--color-black);
        line-height: 21px;
    }

    .marketingText > hr {
        border: 2px solid #000000;
    }

    .content {
        width: calc(100% - 40px);
        padding-bottom: 20px;
    }

    .content > p {
        margin-top: 10px;
        color: var(--color-black);
    }

    .cityBlock {
        margin: 0;
    }

    .bitrixBlock {
        margin-top: 35px;
    }

    .bitrixBtn {
        padding: 17px 0;
        background-color: #353535;
        color: var(--color-border);
    }

    .bitrixBtn {
        font-weight: 600;
    }
}