@font-face {
    font-family: 'Futura PT';
    font-style: normal;
    font-weight: 100;
    src: local('Futura'),
    url(../fonts/futura/FuturaPT-Light.woff2) format('woff2'),
    url(../fonts/futura/FuturaPT-Light.woff) format('woff'),
    url(../fonts/futura/FuturaPT-Light.ttf) format('ttf'),
    url(../fonts/futura/FuturaPT-Light.eot) format('eot');
}

@font-face {
    font-family: 'Futura PT';
    font-style: normal;
    font-weight: 400;
    src: local('Futura'),
    url(../fonts/futura/FuturaPT-Book.woff2) format('woff2'),
    url(../fonts/futura/FuturaPT-Book.woff) format('woff'),
    url(../fonts/futura/FuturaPT-Book.ttf) format('ttf'),
    url(../fonts/futura/FuturaPT-Book.eot) format('eot');
}

@font-face {
    font-family: 'Futura PT';
    font-style: normal;
    font-weight: 500;
    src: local('Futura'),
    url(../fonts/futura/FuturaPT-Medium.woff2) format('woff2'),
    url(../fonts/futura/FuturaPT-Medium.woff) format('woff'),
    url(../fonts/futura/FuturaPT-Medium.ttf) format('ttf'),
    url(../fonts/futura/FuturaPT-Medium.eot) format('eot');
}

@font-face {
    font-family: 'Futura PT';
    font-style: normal;
    font-weight: 600;
    src: local('Futura'),
    url(../fonts/futura/FuturaPT-Bold.woff2) format('woff2'),
    url(../fonts/futura/FuturaPT-Bold.woff) format('woff'),
    url(../fonts/futura/FuturaPT-Bold.ttf) format('ttf'),
    url(../fonts/futura/FuturaPT-Bold.eot) format('eot');
}

@font-face {
    font-family: 'Futura PT';
    font-style: normal;
    font-weight: 700;
    src: local('Futura'),
    url(../fonts/futura/FuturaPT-ExtraBold.woff2) format('woff2'),
    url(../fonts/futura/FuturaPT-ExtraBold.woff) format('woff'),
    url(../fonts/futura/FuturaPT-ExtraBold.ttf) format('ttf'),
    url(../fonts/futura/FuturaPT-ExtraBold.eot) format('eot');
}

